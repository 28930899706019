@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Inter var,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

button:disabled {
    background-color: #eee;
    color: #aaa;
}

h1 {
    font-size: 2.5rem;
    font-weight: 700;
}

.divider {
    border-top: 1px solid #ccc;
    margin: 5px;
}

.hideParent .hideButton{
    visibility: hidden;
}

.hideParent:hover .hideButton{
    visibility: visible;
}